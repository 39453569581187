import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import {
  getExternalAccessToken,
  getExternalAuthorizationCode,
  getPartnerInfos,
  loginWithExternalAccount,
  loginWithInternalAccount,
} from "../../actions/login";
import { Button, CircularProgress, Link, TextField, Typography } from "@material-ui/core";
import ExternalLogin from "./ExternalLogin";
import network from "../../actions/external/network";
import { loadPartners } from "../../actions/partners";
import BackgroundPaper from "../common/BackgroundPaper";

const mapStateToProps = (state) => ({
  auth: state.auth,
  login: state.login,
  config: state.config,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  getPartnerInfos: (partner) => dispatch(getPartnerInfos(partner)),
  loginWithInternalAccount: (username, password) => dispatch(loginWithInternalAccount(username, password)),
  getExternalAuthorizationCode: () => dispatch(getExternalAuthorizationCode()),
  getExternalAccessToken: (authorizationCode) => dispatch(getExternalAccessToken(authorizationCode)),
  loginWithExternalAccount: (idToken) => dispatch(loginWithExternalAccount(idToken)),
  loadPartners: () => dispatch(loadPartners()),
});

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      loading: false,
    };
  }

  componentDidMount() {
    // nécessaire sous firefox en cas de clic sur le bouton "back" du navigateur depuis le CAS
    // afin que le composant soit de nouveau monté
    // https://stackoverflow.com/questions/2638292/after-travelling-back-in-firefox-history-javascript-wont-run
    window.onunload = () => {};

    const { authorizationCode, state } = this.getAuthorizationCodeAndState();
    if (authorizationCode != null && state != null && state === this.props.login.state) {
      this.loginWithExternalAccount(authorizationCode);
    }
  }

  componentWillUnmount() {
    window.onunload = undefined;
  }

  getAuthorizationCodeAndState = () => {
    const params = new URL(document.location).searchParams;
    return {
      authorizationCode: params.get("code"),
      state: params.get("state"),
    };
  };

  redirectToSourcePage = () => {
    this.props.loadPartners();

    const params = new URL(document.location).searchParams;
    this.props.history.replace(params.get("redirect") || "/");
  };

  loginWithInternalAccount = async (event) => {
    event.preventDefault();

    try {
      this.setState({ loading: true });
      await this.props.loginWithInternalAccount(this.state.username, this.state.password);
      this.redirectToSourcePage();
    } catch (error) {
      this.setState({ loading: false });
      this.showToastError(error.message);
    }
  };

  getExternalAuthorizationCode = async (partner) => {
    try {
      this.setState({ loading: true });
      await this.props.getPartnerInfos(partner);
      await this.props.getExternalAuthorizationCode();
    } catch (error) {
      console.error("an error happened while fetching partner's informations", error);
      this.showToastError();
      this.setState({ loading: false });
      this.props.history.replace("/login");
    }
  };

  loginWithExternalAccount = async (authorizationCode) => {
    try {
      this.setState({ loading: true });
      const { id_token } = await this.props.getExternalAccessToken(authorizationCode);

      if (this.props.linkExternalAccount) {
        await this.linkExternalAccount(id_token);
      } else {
        await this.props.loginWithExternalAccount(id_token);
      }
      this.redirectToSourcePage();
    } catch (error) {
      console.error("an error happened while logging-in with partner", error);
      this.showToastError(error.message);
      this.setState({ loading: false });
      this.props.history.replace("/login");
    }
  };

  linkExternalAccount = async (idToken) => {
    const { t } = this.props;

    try {
      const url = `/api/utilisateurs/${this.props.auth.idUtilisateur}/partners/${this.props.login.partnerInfos.idApplicationTierce}`;

      await network.fetch(url, {
        method: "POST",
        body: idToken,
      });

      toastr.success(
        "OK",
        t("login.linking-success", `Votre compte ${this.props.login.partnerInfos.libelle} a bien été lié.`)
      );
    } catch (error) {
      console.error("an error happened while linking user with partner", error);
      this.showToastError(error.message);
    }
  };

  showToastError = (message) => {
    const { t } = this.props;
    toastr.error(t("error", "Erreur"), message || t("login.error", "Une erreur est survenue lors de la connexion"));
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  renderInternalLogin() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <form className="d-flex flex-column align-items-center w-100" onSubmit={this.loginWithInternalAccount}>
          <TextField
            label={t("login.username", "Identifiant :")}
            type="text"
            name="username"
            value={this.state.username}
            onChange={this.handleInputChange}
            variant="outlined"
            className="mt-1"
          />
          <TextField
            label={t("login.password", "Mot de passe :")}
            type="password"
            name="password"
            value={this.state.password}
            onChange={this.handleInputChange}
            variant="outlined"
            className="mt-1"
          />
          <Button
            className="mt-2"
            disabled={this.state.username === "" || this.state.password === ""}
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
          >
            {t("login.log-in", "Se connecter")}
          </Button>
          <Link component={RouterLink} to="/reset-password" className="mt-05 mb-2">
            {t("login.forgotten-password", "Mot de passe oublié")}
          </Link>
          {/*<Button
            component={RouterLink}
            to="/register"
            variant="contained"
            color="secondary"
            className="my-2"
            fullWidth
          >
            {t("login.register", "Créer un compte")}
          </Button>*/}
        </form>
      </React.Fragment>
    );
  }

  renderForm() {
    const { linkExternalAccount, connectWithPartnerClientId } = this.props;
    const { authorizationCode } = this.getAuthorizationCodeAndState();

    return (
      <React.Fragment>
        {!linkExternalAccount && connectWithPartnerClientId == null && this.renderInternalLogin()}
        {authorizationCode == null && (
          <ExternalLogin
            onClick={this.getExternalAuthorizationCode}
            linkExternalAccount={linkExternalAccount}
            connectWithPartnerClientId={connectWithPartnerClientId}
          />
        )}
        <Typography className="d-flex justify-end mt-2" style={{ fontSize: "smaller" }}>
          v{this.props.app.version}
        </Typography>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <BackgroundPaper className="paper" square>
          {this.state.loading ? <CircularProgress /> : this.renderForm()}
        </BackgroundPaper>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login)));
